.AddInvite-container{
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    font-style: normal;

  .invite-type-title {
    text-align: center;
  }


  .edit-invite-main-buttons-cont {
    display: flex;
    flex-direction: column;

    .eim-button {
        flex:1;
        border: 2px solid #e5f7fe;
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 5px 0;
        cursor: pointer;
        background-color: #E5F7FE;

        &:hover {
          background: #e5f7fe;
        }

      &.yellow {
        border: 2px solid #E3B505;
        background-color: #E3B505;
        color: #fff;

        &:hover {
          background: #edbe07;
        }
      }

      &.red {
        border: 2px solid #f26157;
        background-color: #f26157;
        color: #fff;

        &:hover {
          background: #fa6a60;
        }
      }
    }
  }

  .changeTemplateButton {
    width: 70%;
    text-align: center;
    background-color: #E3B505;
    margin: 0 auto 30px auto;
    padding: 5px;
    border-radius: 20px;
    color: white;
    cursor: pointer;
  }

  .chooseTemplate {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;

    .item {
      display: flex;
      //height: 400px;
      overflow: hidden;
      cursor: pointer;
      border: 1px solid #ccc;
      //margin: 10px;
      width: 30.3333%;
      position: relative;

      img {
        width: 100%;
        object-fit: cover;
      }

      &.with-title {
        flex-direction: column;
        //height: 400px;

        img {
          width: 100%;
          //height: 90%;
          object-fit: cover;
        }

        .item-title {
          display: flex;
          height: 2.5rem;
          align-items: center;
          justify-content: center;
          font-size: 1.2rem;
        }

        .item-intro-anim-badge {
          position: absolute;
          top: 0;
          font-weight: 500;
          background-color: #0a9445;
          color: #ffffff;
          padding: 2px 5px;
        }

        .item-intro-screen-badge {
          position: absolute;
          top: 0;
          right: 0;
          font-weight: 500;
          background-color: #0a9445;
          color: #ffffff;
          padding: 2px 5px;
        }
      }

      .animation-type-image-cont {
        position: relative;

        .type-icon-cont{
          position: absolute;
          top:0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 10;

          .rs-icon {
            font-size: 3rem;
            color: #05AFF2;
            text-shadow: 2px 2px #666
          }
        }
      }

      .type-description {
        font-weight: 500;
        margin-top: 0.5rem;
        padding: 0 5px;
      }

    }
  }

  .chooseTemplate-filters {
   text-align: center;
    margin-top: 5px;

    .item {
      display: inline-block;
      border: 1px solid rgb(5, 175, 242);
      color: rgb(5, 175, 242);
      border-radius: 10px;
      padding: 5px 10px;
      margin-right: 10px;
      cursor: pointer;
    }

    .item.active {
      background-color: rgb(5, 175, 242);
      color: #fff;
    }
  }

  .eic-tabs {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-bottom: 1rem;

    .eic-tab {
      display: flex;
      flex: 1;
      border-radius: 15px;
      background-color: #E5F7FE;
      color: #6B6996;
      cursor: pointer;
      padding: 10px 10px;
      justify-content: center;
      align-items: center;

      &.active {
        background-color: #05AFF2;
        color: #ffffff;
      }
    }
  }

    .Footer{
		display: flex;
		justify-content: space-around;
        width: 100%;
        margin: 10px auto;

        .add-button{
            height:6dvh;
            border-radius: 20px;
            width: 100%;
			margin-bottom: 10px;
            font-size: 20px;
            font-family: 'Signika', sans-serif;
            font-weight: 700;
            font-style: normal;
            margin-bottom: 20px;
        }

        #disclaimer{
            font-size:10px;
        }
    }

    .AddInvite-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //height: 80dvh;
        overflow-y: scroll;
        flex:1;
        padding-top: 10px;

        .Upload-zone{
            margin-bottom: 20px;
            text-align: center;

            #text{
                padding: 50px 2px;
            }

            h3{
                font-weight: 800;

                @media screen and (max-width: 765px) {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

			.rs-uploader-text .rs-uploader-file-items{
				margin-top: 0px;
			}
        }

        .input-zone{
          display:flex;
          flex-direction: column;
          text-align: center;
          width: 100%;
          max-width: 700px;
          //overflow-y: scroll;
          margin: auto;
          color: #221d5d;
          padding: 0 1rem;

          &.large {
            width: 100%;
            max-width: 1202px;
          }

			.ChangeChoiceButton{
				width: 70%;
				text-align: center;
				background-color: #E3B505;
				margin:10px auto 30px auto;
				padding: 5px;
				border-radius: 20px;
				color: white;
			}

			.Section{
				margin-top: 20px;

				.Title{
					text-align: center;
					font-size: 18px;
				}

				.Options-Row{
					display: flex;
					flex-direction: row;
					justify-content: center;
					margin: 10px 0px;

					@mixin option {
						width: 50px;
						padding: 10px;
						border-radius: 20px;
					}

					.active-option{
						@include option;
						background-color: #05AFF2;
						color: #FFFFFF
					}

					.inactive-option{
						@include option;
						background-color: #E5F7FE;
						color: #6B6996;
					}
				}
			}

			.Input_Type_Options{
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				flex-wrap: wrap;
				overflow-y: scroll;

				.Input_Type_Option{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					font-size: 15px;
                  margin-bottom: 20px;

					.Circle{
						display: flex;
						justify-content: center;
						align-items: center;
						height: 100px;
						width: 100px;
						margin: 10px 50px;
						border-radius: 50%;
						background-color: #E5F7FE;
					}
				}
			}

			.Input_Type_Options::-webkit-scrollbar{
				display: none;
			}

			.Invite-Design {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin: 10px 0px;

				.color-container{
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;

					.color-choice{
						display: flex;
						flex-direction: row;

						.styles_closeButton__20ID4{
							display: none;
						}
					}
				}
			}

            .InputGroup-Container{
                width: 300;
                width: 100%;
                margin-bottom: 30px;
            }

            .AddHours-date-picker{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-family: 'Signika', sans-serif;
                font-weight: 700;
                font-style: normal;
            }

            //.rs-picker-toggle .rs-picker-toggle-caret{
            //    display: none;
            //}

            .rs-picker-toggle .rs-picker-toggle-placeholder{
                color: #221d5d;
                padding-left: 0px;
                font-weight: 700;
            }

            .AddJob-Icon{
                color: #221d5d;
            }

            #job-select::placeholder{
                padding-left:10px;
            }

            #job-select{
                background: transparent;
                color: #221d5d;
                border: none;
                border-radius: 0px;
                border-bottom: 1px solid #221d5d;
                width : 100%;
                display: flex;
                flex-direction: row;
                font-family: 'Signika', sans-serif;
                font-weight: 700;
                font-style: normal;
            }

            .AddJob-Container{
                margin-bottom: 30px;
                margin-top: 30px;

                .rs-picker-toggle-value{
                    padding-left: 25px;
                }

                .rs-picker-toggle .rs-picker-toggle-placeholder{
                    color: #221d5d;
                    padding-left:25px;
                }
            }

            #input{
                background: transparent;
                color: #221d5d;
                border: none;
                border-radius: 0px;

				border-bottom: 1px solid #221d5d;
            }

            #inputText{
                background: transparent;
                color: #221d5d;
                border: none;
                border-radius: 0px;
                border-bottom: 1px solid #221d5d;
                // height: 13dvh;
                padding-left:35px;
            }

            #input::placeholder{
                color: #221d5d;
            }

            #inputText::placeholder{
                color: #221d5d;
            }
        }

		.input-zone::-webkit-scrollbar{
			display: none;
		}

        .event-code{
            font-size: 17px;
            font-weight: 700;
        }

        h4{
            margin: 10px auto;
			font-size: 25px;
            color: #221d5d;
        }

      .section-info-cont {
        background-color: #E5F7FE;
        padding: 1rem 1.5rem 1rem 1.5rem;
        border-radius: 0.5rem;
        flex:1;
        text-align: left;
        overflow: hidden;
        margin-bottom: 1rem;

        .footer-cont {
          display: flex;
          flex-direction: row;
          text-align: right;
          margin: 0 0 0 0;

          .cta-col {
            flex:1;
            display: flex;
            flex-direction: row;
            font-weight: 400;
            font-size: 0.8rem;

            .text-col {
              flex:1;
              display: flex;
              text-align: right;
              justify-content: flex-end;
              align-items: flex-end;
              padding-right: 0.5rem;
            }

            .img-col {
              display: flex;
              text-align: right;
              justify-content: flex-end;
              align-items: flex-end;

              img {
                rotate: 35deg;
                width: 2rem;
              }
            }
          }
          .right-col {
            flex:1;
          }
        }

        .header-cont {
          display: flex;
          flex-direction: row;

          .title-col {
            padding: 0.5rem 0 0 0;
            .title-1 {
              font-size: 1rem;
              font-weight: 700;
              text-transform: uppercase;
              line-height: 1rem;
            }
            .title-2 {
              font-size: 3rem;
              font-weight: 700;
              text-transform: uppercase;
              line-height: 3rem;
              margin-bottom: 1.5rem;
            }
            .title-2-small {
              font-size: 2rem;
              font-weight: 700;
              text-transform: uppercase;
              line-height: 2rem;
              margin-bottom: 1.5rem;
            }
          }
          .icon-col {
            flex:1;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 1rem;
            padding-left: 1rem;

            img {
              height: 60px;
            }
          }
        }

        .description {
          margin-bottom: 1rem;
          line-height: 1.1rem;
          font-size: 0.9rem;
          font-weight: 500;
        }
      }
    }
}

.invite-lang-change-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;

  .lang-item {
    border: 2px solid #e5f7fe;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 5px 20px;
    cursor: pointer;

    &.active {
      background: #e5f7fe;
    }
  }

}


@media screen and (max-width: 700px) {
  .AddInvite-container .chooseTemplate .item.with-title {
    width: 100%;
  }
  .AddInvite-container .chooseTemplate .item {
    width: 100%;
  }
}

@media screen and (min-width: 701px) and (max-width: 900px) {
  .AddInvite-container .chooseTemplate .item.with-title {
    width: 40%;
  }
  .AddInvite-container .chooseTemplate .item {
    width: 40%;
  }
}

@media screen and (min-width: 700px) {
  .AddInvite-container {
    .edit-invite-main-buttons-cont {
      flex-direction: row;
      gap: 0.5rem;
    }
  }
}


.invite-download-size-selector {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 5px;

  .option {
    display: flex;
    flex:1;
    padding: 8px;
    border-radius: 20px;
    background-color: #E5F7FE;
    color: #6B6996;
    cursor: pointer;
    justify-content: center;

    &.active {
      background-color: #05AFF2;
      color: #FFFFFF;
    }
  }


}
