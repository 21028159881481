.iacc-cont {
  margin-bottom: 1rem;

  .config-item-row {
    margin-bottom: 1rem;
  }

  .config-item-color-field {
    width: 100%;
    max-width: 150px;
    height: 3rem;
    border: 0;
    border-radius: 5px;
  }
}
